import { congstarScrollTo } from "../util/congstarScrollTo";

export const buttonScrollTo = (attributeName: string) => {
  const elements = document.querySelectorAll(`[${attributeName}]`);

  Array.from(elements).forEach(function (element) {
    const attributeValue = element.getAttribute(attributeName) as string;

    element.addEventListener("click", (event) =>
      congstarScrollTo(attributeValue, event),
    );
  });
};
